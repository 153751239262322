import PropTypes from "prop-types"
import React from "react"
import Markdown from "../Markdown"
import Link from "../Link"
import { Box, Heading } from "rebass/styled-components"

const VerticalCard = ({ title, content, image, link, linklabel }) => {
  const hasLinnk = link !== null && link !== "" && linklabel !== null && linklabel !== ""
  return (
    <Box variant="verticalCard" height="100%" pb={2}>
      {image && (
        <>
          <Box
            width={1}
            mb={4}
            height="300px"
            sx={{
              backgroundImage: `url(${
                typeof image === "string" ? image : image.childImageSharp.fluid.src
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></Box>
          <Box variant="gridItem" width={1}>
            {title && <Heading as="h3">{title}</Heading>}
            {content && <Markdown>{content}</Markdown>}
            {hasLinnk && (
              <Box py={2} width={[1]}>
                <Link href={link}>{linklabel}</Link>
              </Box>
            )}
          </Box>
        </>
      )}
      {!image && (
        <Box variant="gridItem" width={[1]}>
          {title && <Heading as="h3">{title}</Heading>}
          {content && <Markdown>{content}</Markdown>}
          {hasLinnk && (
            <Box py={2} width={[1]}>
              <Link href={link}>{linklabel}</Link>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

VerticalCard.propTypes = {
  content: PropTypes.any,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.any,
      }),
    }),
  }),
  link: PropTypes.string,
  linklabel: PropTypes.string,
  title: PropTypes.any,
}

export default VerticalCard
