import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import Wrapper from "../Wrapper";

const Markup = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Markdown>{data.content}</Markdown>
    </Wrapper>
  );
};

Markup.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.any,
    title: PropTypes.any
  })
};
export default Markup;

export const query = graphql`
  fragment MarkupBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
  }
`;
