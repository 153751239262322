import HeroHeader2 from "./Variants/HeroHeader2"
import Profs from "./Variants/Profs"
import sliderHome from "./Variants/sliderHome"

const variants = {
  HeroHeader2: HeroHeader2,
  Profs: Profs,
  sliderHome: sliderHome,
}

const BlockVariant = block => {
  if (block.blockSettings) {
    if (block.blockSettings.variant) {
      if (variants[block.blockSettings.variant]) {
        const VariantComp = variants[block.blockSettings.variant]
        if (VariantComp) {
          return VariantComp
        }
      }
    }
  }
  return false
}
export default BlockVariant
