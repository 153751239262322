import React from "react";
import { graphql } from "gatsby";
import Page from "./Page";
import Blocks from "../components/Blocks";
import PropTypes from "prop-types";
export const PageRawTemlate = ({ data }) => {
  return <Blocks blocks={data.blockscollection} />;
};
const PageTemplate = ({ data }) => {
  const infos = {
    title: data.markdownRemark.frontmatter.title,
    description: data.markdownRemark.frontmatter.description,
    blockscollection: data.markdownRemark.frontmatter.blockscollection
  };
  return (
    <Page title={infos.title} description={infos.description}>
      <PageRawTemlate data={infos} />
    </Page>
  );
};
PageTemplate.propTypes = {
  data: PropTypes.object
};
PageRawTemlate.propTypes = {
  data: PropTypes.object
};
export default PageTemplate;

export const pageQuery = graphql`
  query PageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        blockscollection {
          ...Blocks
        }
      }
    }
  }
`;
