import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import { Box, Heading, Flex } from "rebass/styled-components";

const Profs = ({ data }) => {
  data.cards = data.cards || [];
  return (
    <Wrapper data={data}>
      <Flex variant="grid" alignItems="start">
        {data.cards.map((card, i) => (
          <Flex
            key={"card-" + i}
            py={4}
            width={[1, 1, 1 / 3]}
            flexWrap={["wrap", "initial", "wrap"]}
            justifyContent={["center", "initial", "center"]}
            alignItems={["center"]}
          >
            {card.image && (
              <>
                <Box variant="gridItem" width={[1 / 2, 1 / 4, 2 / 3]} py={4}>
                  <CustomImage img={card.image} variant="avatar" />
                </Box>
                <Box variant="gridItem" width={[1, 3 / 4, 1]}>
                  {card.title && (
                    <Heading as="h2" textAlign={["center", "left", "center"]}>
                      {card.title}
                    </Heading>
                  )}
                  {card.content && <Markdown>{card.content}</Markdown>}
                </Box>
              </>
            )}
            {!card.image && (
              <Box variant="gridItem" width={[1]}>
                {card.title && (
                  <Heading as="h2" textAlign="center">
                    {card.title}
                  </Heading>
                )}
                {card.content && <Markdown>{card.content}</Markdown>}
              </Box>
            )}
          </Flex>
        ))}
      </Flex>
    </Wrapper>
  );
};

Profs.propTypes = {
  data: PropTypes.shape({
    cards: PropTypes.array
  })
};
Profs.defaultProps = {
  data: {
    cards: []
  }
};

export default Profs;
