import PropTypes from "prop-types";
import React from "react";
import CustomImage from "../../UI/CustomImage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Wrapper from "../Wrapper";
import { Box } from "rebass/styled-components";
import SquareBox from "../../UI/SquareBox";

const Carousel = ({ data }) => {
  var settings = {
    dots: false,
    autoplaySpeed: 2000,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: data.carouselItems.length > 4 ? 4 : data.carouselItems.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: data.carouselItems.length > 3 ? 3 : data.carouselItems.length,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: data.carouselItems.length > 1 ? 2 : data.carouselItems.length,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Wrapper data={data}>
      <Slider {...settings}>
        {data.carouselItems.map((carouselItem, i) => (
          <Box p="15px" height="300px" key={"feature-" + i}>
            <h4>{carouselItem.name}</h4>
            <Box maxWidth="400px">
              <SquareBox>
                <CustomImage
                  img={carouselItem.image}
                  width="100%"
                  height="100%"
                  sx={{ ".gatsby-image-wrapper": { minHeight: "100%" } }}
                />
              </SquareBox>
            </Box>
          </Box>
        ))}
      </Slider>
    </Wrapper>
  );
};

Carousel.propTypes = {
  data: PropTypes.shape({
    carouselItems: PropTypes.array
  })
};

Carousel.defaultProps = {
  data: {
    carouselItems: []
  }
};
export default Carousel;
