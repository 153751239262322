import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"
import Header from "./Header"
import Markup from "./Markup"
import Features from "./Features"
import Gallery from "./Gallery"
import Carousel from "./Carousel"
import Numbers from "./Numbers"
import Form from "./Form"
import FormMap from "./FormMap"
import Separator from "./Separator"
import Cards from "./Cards"
import BlockVariant from "./BlockVariant"

const blocksComponents = {
  Header: Header,
  Markup: Markup,
  Features: Features,
  Gallery: Gallery,
  Carousel: Carousel,
  Numbers: Numbers,
  Form: Form,
  FormMap: FormMap,
  Separator: Separator,
  Cards: Cards,
}

const Block = ({ block }) => {
  const BlockComp = blocksComponents[block.type]
  const Variant = BlockVariant(block)
  if (Variant) {
    return <Variant data={block} />
  }
  if (BlockComp) {
    return <BlockComp data={block} />
  }
  return <> </>
}

Block.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.string,
  }),
}
export default ({ blocks }) => {
  blocks = blocks || []
  return blocks.map((blockItem, i) => <Block block={blockItem} key={i} />)
}

export const query = graphql`
  fragment Blocks on MarkdownRemarkFrontmatterBlockscollection {
    type
    blockSettings {
      showTitle
      blockname
      bg
      color
      containerFluid
      id
      variant
      parallax
      fullHeight
      align
      padding {
        bottom
        top
      }
      bgImg {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    ...HeaderBlock
    ...MarkupBlock
    ...GalleryBlock
    ...CarouselBlock
    ...NumbersBlock
    ...FeaturesBlock
    ...FormBlock
    ...FormMapBlock
    ...SeparatorBlock
    ...CardsBlock
  }
`
