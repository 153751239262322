import PropTypes from "prop-types";
import React from "react";
import { Box, Flex } from "rebass/styled-components";

const SquareBox = ({ children, ...props }) => {
  return (
    <Box variant="squareBox" {...props}>
      <Flex sx={{ position: "absolute" }} alignItems="center" width="100%" height="100%">
        {children}
      </Flex>
    </Box>
  );
};

SquareBox.propTypes = {
  children: PropTypes.any
};

export default SquareBox;
