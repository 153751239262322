import PropTypes from "prop-types"
import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Button } from "rebass/styled-components"
import Icofont from "./Icofont"


const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 }
}
const containerVariant = {
  initial: { top: "60%", opacity: 0, transition: { type: "spring" } },
  isOpen: { top: "50%", opacity: 1 },
  exit: { top: "60%", opacity: 0 }
}
const Modal = ({ handleClose, children, isOpen, bypassBackgroundClose }) => {
  bypassBackgroundClose = bypassBackgroundClose || false
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
          variants={modalVariant}
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "9999",
            background: "rgba(0, 0, 0, 0.3)"
          }}
        >
          {!bypassBackgroundClose && (
            <motion.div
              onClick={handleClose}
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "0",
                background: "transparent"
              }}
            />
          )}
          <motion.div
            style={{
              width: "auto",
              height: "auto",
              backgroundColor: "transparent",
              position: "absolute",
              zIndex: 1,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              overflow: "scroll",
              maxHeight: "100%",
              maxWidth: "100%"
            }}
            variants={containerVariant}
          >
            <Button
              onClick={handleClose}
              variant="ninja"
              sx={{
                position: "absolute",
                top: "20px",
                right: "20px"
              }}
            >
              <Icofont icon="close" />
            </Button>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

Modal.propTypes = {
  children: PropTypes.any,
  handleClose: PropTypes.any,
  isOpen: PropTypes.any,
  bypassBackgroundClose: PropTypes.bool
}

export default Modal
