import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import { Box } from "rebass/styled-components";
import CustomImage from "../../UI/CustomImage";
import Markdown from "../../UI/Markdown";

const Features = ({ data }) => {
  data.items = data.items || [];
  return (
    <Wrapper data={data}>
      <Markdown>{data.intro}</Markdown>
      <Grid childWidth={[1, 1, 1 / data.items.length]}>
        {data.items.map((item, i) => (
          <Box key={"num-" + i} py={[6, 6, 0]} textAlign="center" sx={{h2:{fontSize:"2em"}}}>
            <Box
width="50%"
mx="auto"
              px={5}
              sx={{
width:"50%",
                ".gatsby-image-wrapper > div": {
                  paddingBottom: "100% !important" /* your aspect ratio */
                }
              }}
            >
              <CustomImage img={item.image} />
            </Box>
            <Markdown>{item.content}</Markdown>
          </Box>
        ))}
      </Grid>
    </Wrapper>
  );
};

Features.propTypes = {
  data: PropTypes.shape({
    intro: PropTypes.any,
    items: PropTypes.array,
    title: PropTypes.any,
    content: PropTypes.any
  })
};
export default Features;

export const query = graphql`
  fragment FeaturesBlock on MarkdownRemarkFrontmatterBlockscollection {
    type
    items {
      content
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 200, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
